import React from "react"
import { Link } from "gatsby"
import Moment from "react-moment"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import SubscribeForm from "../components/subscribe-form"

const EventDetailTemplate = ({ pageContext }) => {
  const siteTitle = "KXP Events - " + pageContext.title

  return (
    <Layout title={siteTitle}>
      <SEO title={siteTitle} description={pageContext.description} image={pageContext.image} />
      <article className="col-md-6 col-12">
        <header className="mb-5">
          <h3
            style={{
              marginTop: rhythm(1),
              marginBottom: "1.5rem",
            }}
          >
            {pageContext.title}
          </h3>
          <p
            style={{
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {pageContext.description}
          </p>
          <p>
            Quando: <Moment format="DD/MM, YYYY">{pageContext.date}</Moment>
            <br />
            Duração: {pageContext.duration} <br />
            Público-Alvo: {pageContext.targetAudience} <br />
          </p>
        </header>

        <SubscribeForm eventId={pageContext._id}></SubscribeForm>

        <footer></footer>
      </article>

      {/* <nav>
        <Link
          style={{ fontWeight: 500, fontSize: "0.8rem", color: "limegreen" }}
          to={`/`}
        >
          Voltar
        </Link>
      </nav> */}
    </Layout>
  )
}

export default EventDetailTemplate
