import React from "react"
import { navigate } from "gatsby"
const axios = require('axios');

export default class SubscribeForm extends React.Component {
  state = {
    firstname: ``,
    lastname: ``,
    emailaddress: ``,
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = async event => {
    event.preventDefault()

    try {
      // await axios.post("http://localhost:7071/api/Subscribe", {
      //   eventId: this.props.eventId,
      //   firstName: this.state.firstname,
      //   lastName: this.state.lastname,
      //   email: this.state.emailaddress,
      // });


      await axios({
        method: "POST",
        url: "https://func-events-api.azurewebsites.net/api/subscribe",
        data: {
          eventId: this.props.eventId,
          firstName: this.state.firstname,
          lastName: this.state.lastname,
          email: this.state.emailaddress,
        }
      });

      navigate("/subscribe-success")
    }
    catch {
      navigate("/subscribe-error")
    }

  }

  render() {
    return (
      <>
        <h3>Inscreva-se!</h3>
        <form className="mt-4" onSubmit={this.handleSubmit} submit="#">
          <div className="form-group">
            <label htmlFor="firstname">Nome</label>
            <input
              type="text"
              className="form-control"
              name="firstname"
              placeholder="Nome"
              onChange={this.handleInputChange}
              required
            ></input>
          </div>

          <div className="form-group">
            <label htmlFor="lastname">Sobrenome</label>
            <input
              type="text"
              className="form-control"
              name="lastname"
              placeholder="Sobrenome"
              onChange={this.handleInputChange}
              required
            ></input>
          </div>

          <div className="form-group">
            <label htmlFor="emailaddress">Email</label>
            <input
              className="form-control"
              name="emailaddress"
              placeholder="Insira seu email"
              onChange={this.handleInputChange}
              required
            ></input>
          </div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </>
    )
  }
}
